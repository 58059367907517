import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // react için gerekli olan i18next'in react entegrasyonunu etkinleştirin
  .init({
    resources: {
      en: {
        translation: {
          aboutmainpage:
            " Akdeniz Konsept is an architecture and construction company founded in 2021. We are a team specialized in different projects, from wooden floors to facade coverings, from wooden furniture to special concept houses. Our aim...",
          allrights: "All rights reserved.",
          contact: "Contact",
          detail: "Detail",
          institutional: "Institutional",
          whoweare: "Catalog",
          home: "Home",
          references: "References",
          referencestextone: "Projects where dreams are possible",
          furniture: "Furniture",
          floorandfacade: "FLOOR AND FACADE COATINGS",
          woodenconstruction: "WOODEN CONSTRUCTION",
          woodenhouse: "Wooden House",
          graphicdesign: "Graphic Drawing and Design",
          aboutus1: "About Us",
          aboutus2:
            "Akdeniz Konsept is an architecture and construction company founded in 2021. We are a team specialized in different projects, from wooden floors to facade coverings, from wooden furniture to special concept houses. Our aim is to offer warm, durable and aesthetic living spaces to our customers.",
          aboutus3: "Our Vision",
          aboutus4:
            "Our mission is to design and build functional living spaces by combining creativity with natural materials. We want to meet the expectations of our customers by reflecting the naturalness and warmth of wood in our projects.",
          aboutus5: "Our Mission",
          aboutus6:
            "Our vision is to be constantly innovative and to offer unique projects to our customers by developing different design concepts. We aim to become a nationally and internationally renowned company in the future.",
          aboutus7: "Our Goals and Future Goals",
          aboutus8:
            "Our goals are to ensure customer satisfaction, adopt the principle of sustainability and keep up with technological developments. Our future goals are to offer sustainable living spaces to more customers, to lead new projects and to continue growth.",
          aboutus9: "International Services",
          aboutus10:
            "Akdeniz Concept also operates in projects in Türkiye and abroad. With our international experience and expert staff, we produce designs and projects suitable for different cultures. We work to create sustainable and aesthetic living spaces around the world.",
          phone: "Phone",
          email: "E-mail",
          socialmedia: "Social Media",
          office: "Office",
          woodfactory: "Wood Factory",
          furniturefactory: "Furniture Factory",
          contactus: "Contact Us",
          allprojects: "All Projects",
          furniture: "Furniture",
          woodenconstruction: "Wooden Construction",
          floorandfacadecoverings: "Floor and Facade Coverings",
          woodenhouse: "Wooden house",
          details: "Details",
          category: "Category",
          pergola: "Pergola",
          banister: "Banister",
          flooring: "Flooring",
          facadecladding: "Facade Cladding",
          hallowfacadecladding: "Hollow Facade Cladding",
          gridcoverings: "Grid Coverings",
          deckcoatings: "Deck Coatings",

          beachbuildings: "Beach Buildings",
          bridge: "Bridge",
          company: "Company",
          othercompany: "Other Companies",
          enddate: "End Date",
          totalarea: "Total Area",
          place: "Place",
          workdone: "Work Done",
          gardenfurniture: "Garden Furnitures",
          streetfurniture: "Street Furniture",
          beachfurniture: "Beach Furniture",
          platforms: "Platforms",
          restaurant: "Restorants",
          singlehouse: "Single Storey Wooden Buildings",
          doublehouse: "Double Storey Wooden Buildings",
          tinyhouse: "Tiny Houses",
          Bungalows: "Bungalows",
          trianglehouses: "Triangle Houses",
          graphicdrawing: "Graphic Drawing",
          design: "Design",
        },
      },
      tr: {
        translation: {
          aboutmainpage:
            "Akdeniz Konsept, 2021 yılında kurulmuş bir mimarlık ve inşaat firmasıdır. Ahşap zeminlerden cephe kaplamalara, ahşap mobilyalardan özel konsept evlere kadar farklı projelerde uzmanlaşmış bir ekibiz. Amacımız... ",
          institutional: "Kurumsal",
          whoweare: "Katalog",
          home: "Anasayfa",
          references: "Referanslar",
          contact: "İletişim",
          allrights: "Tüm hakları saklıdır.",
          referencestextone: "  Hayallerin mümkün olduğu projeler",
          detail: "Detay",
          furniture: "Mobilya",
          woodenconstruction: "AHŞAP KONSTRÜKSİYON",
          floorandfacade: "ZEMİN VE CEPHE KAPLAMALARI",
          woodenhouse: "Ahşap Ev",
          graphicdesign: "Grafik Çizim ve Tasarım",
          aboutus1: "Hakkımızda",
          aboutus2:
            "Akdeniz Konsept, 2021 yılında kurulmuş bir mimarlık ve inşaat firmasıdır. Ahşap zeminlerden cephe kaplamalara, ahşap mobilyalardan özel konsept evlere kadar farklı projelerde uzmanlaşmış bir ekibiz. Amacımız, müşterilerimize sıcak, dayanıklı ve estetik yaşam alanları sunmaktır.",
          aboutus3: "Vizyonumuz",
          aboutus4:
            "Misyonumuz, doğal malzemelerle yaratıcılığı birleştirerek, işlevsel yaşam alanları tasarlamak ve inşa etmek şeklindedir. Ahşabın doğallığını ve sıcaklığını projelerimize yansıtarak, müşterilerimizin beklentilerini karşılamak istiyoruz.            ",
          aboutus5: "Misyonumuz",
          aboutus6:
            "Vizyonumuz, sürekli yenilikçi olmak ve farklı tasarım konseptlerini geliştirerek, müşterilerimize benzersiz projeler sunmak şeklindedir. Gelecekte ulusal ve uluslararası alanda tanınmış bir firma olmayı hedefliyoruz.            ",
          aboutus7: " Amaçlarımız & Gelecek Hedeflerimiz",
          aboutus8:
            "Amaçlarımız, müşteri memnuniyetini sağlamak, sürdürülebilirlik ilkesini benimsemek ve teknolojik gelişmelere ayak uydurmak şeklindedir. Gelecek hedeflerimiz ise, daha fazla müşteriye sürdürülebilir yaşam alanları sunmak, yeni projelerde liderlik yapmak ve büyümeyi sürdürmek şeklindedir.            ",
          aboutus9: "Uluslararası Hizmetler",
          aboutus10:
            "Akdeniz Konsept, Türkiye ve yurt dışındaki projelerde de faaliyet göstermektedir. Uluslararası deneyimimiz ve uzman kadromuzla, farklı kültürlere uygun tasarımlar ve projeler üretiyoruz. Dünya genelinde sürdürülebilir ve estetik yaşam alanları oluşturmak için çalışıyoruz.            ",
          phone: "Telefon",
          email: "E-mail",
          socialmedia: "Sosyal Medya",
          office: "Ofis",
          woodfactory: "Ahşap Fabrikası",
          furniturefactory: "Mobilya Fabrikası",
          contactus: "Bize Ulaşın",
          allprojects: "Tüm Projeler",
          furniture: "Mobilya",
          woodenconstruction: "Ahşap Kontrüksiyon",
          floorandfacadecoverings: "Zemin ve Cephe Kaplamaları",
          woodenhouse: "Ahşap Ev",
          details: "Detaylar",
          category: "Kategori",
          pergola: "Pergole",
          banister: "Korkuluk",
          flooring: "Zemin Kaplama",
          facadecladding: "Cephe Kaplama",
          hallowfacadecladding: "Boşluklu Cephe Kaplama",
          gridcoverings: "Grid Kaplamaları",
          deckcoatings: "Deck Kaplamalar",
          beachbuildings: "Sahil Yapıları",
          bridge: "Köprü",
          company: "Firma",
          othercompany: "Diğer Firmalar",
          enddate: "Bitiş Tarihi",
          totalarea: "Toplam Alan",
          place: "Yer",
          workdone: "Yapılan İş",
          gardenfurniture: "Bahçe Mobilyaları",
          streetfurniture: "Kent Mobilyaları",
          beachfurniture: "Sahil  Mobilyaları",
          platforms: "Platformlar",
          restaurant: "Restorantlar",
          singlehouse: "Tek Katlı Ahşap Yapılar",
          doublehouse: "İki Katlı Ahşap Yapılar",
          tinyhouse: "Tiny House",
          Bungalows: "Bungalovlar",
          trianglehouses: "Üçgen Evler",
          graphicdrawing: "Grafik Çizim",
          design: "Tasarım",
        },
      },
    },
    lng: "tr",
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
