import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { withTranslation } from "react-i18next";

const TabFour = [
  {
    image:
      "/assets/images/ProjePages/Alma-Restaurant-Side/ilk-foto-öncelikli.jpg",
    bigImage:
      "/assets/images/ProjePages/Alma Restaurant-Side/ilk-foto-öncelikli.jpg",
    category: "Logo Design",
    title: "Getting tickets to the big show",
  },
  {
    image: "/assets/images/ProjePages/Alma-Restaurant-Side/alma-2.jpg",
    bigImage: "/assets/images/ProjePages/Alma-Restaurant-Side/alma-2.jpg",
    category: "Freelancer",
    title: "Getting tickets to the big show",
  },
  {
    image: "/assets/images/ProjePages/Alma-Restaurant-Side/almason.jpg",
    bigImage: "/assets/images/ProjePages/Alma-Restaurant-Side/almason.jpg",
    category: "Logo Designer",
    title: "Getting tickets to the big show",
  },
  {
    image: "/assets/images/ProjePages/Alma-Restaurant-Side/ALMA.jpg",
    bigImage: "/assets/images/ProjePages/Alma-Restaurant-Side/ALMA.jpg",
    category: "Logo Designer",
    title: "Getting tickets to the big show",
  },
  {
    image: "/assets/images/ProjePages/Alma-Restaurant-Side/teras.jpg",
    bigImage: "/assets/images/ProjePages/Alma-Restaurant-Side/teras.jpg",
    category: "Logo Designer",
    title: "Getting tickets to the big show",
  },
  // {
  //   image: "/assets/images/ProjePages/Alma Restaurant Side/alma-3.jpg",
  //   bigImage: "/assets/images/ProjePages/Alma Restaurant Side/alma-3.jpg",
  //   category: "Logo Designer",
  //   title: "Getting tickets to the big show",
  // },
];
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab4: 0,
      isOpen: false,
    };
  }
  render() {
    const { t } = this.props;
    const { column } = this.props;
    const { tab4, isOpen } = this.state;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Alma Resort Restaurant" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Alma Resort Restaurant"} />
        {/* End Breadcrump Area */}
        {/* Start Portfolio Area */}

        <div className="rn-portfolio-details bg_color--1">
          <div
            className="row"
            style={{ backgroundColor: "red", padding: "45px" }}
          >
            <div className="col-lg-10">
              <p>AFAFASFSAFASFASF</p>
            </div>
            <div className="col-lg-2">
              <p>AFAFASFSAFASFASF</p>
            </div>
          </div>
        </div>
        {/* <PortfolioGalleryPopup /> GALERİ POP UP ******************************* */}

        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </React.Fragment>
    );
  }
}
export default ProjectDetail;
