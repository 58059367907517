import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import BrandTwo from "../elements/BrandTwo";
import SliderTwo from "../component/slider/SliderTwo";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";
import { Link } from "react-router-dom";
import CustomAccordion from "./CustomAccordion";
import CustomAccordionMobil from "./CustomAccordionMobil";
import { withTranslation } from "react-i18next";

const PortfolioList2 = [
  {
    image: "image-2",
    title: " National Golf Club Köprü",
    link: "/golf-kopru",
  },
  {
    image: "image-20",
    title: "Luxury Üçgen Ev",
    link: "/luxury-ucgen-ev",
  },
  {
    image: "image-22",
    title: "Standart Üçgen Ev",
    link: "/standart-ucgen-ev",
  },
  {
    image: "image-1",
    title: "Alma Restaurant Side ",
    link: "/alma-resort",
  },
  {
    image: "image-2",
    title: " National Golf Club Köprü",
    link: "/golf-kopru",
  },

  {
    image: "image-3",
    title: " Regnum Carya VIP  ",
    link: "/regnum",
  },
  {
    image: "image-14",
    title: "Rixos Premium",
    link: "/rixos",
  },
];

class InteriorLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { t } = this.props;
    return (
      <Fragment>
        <Helmet pageTitle="Akdeniz Konsept" />
        {/* Start Header Area  */}
        <Header />
        {/* End Header Area  */}
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <SliderTwo />
          </div>
        </div>
        {/* End Slider Area   */}
        {/* Start About Us Box  */}
        <div className="about-area ptb--100 ">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-3">
                  <div>
                    <img
                      className="w-200 mobil"
                      src="assets/images/logo/AkdenizKonseptLogo.png"
                      alt="About Images"
                      style={{ height: "150%", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="about-inner inner">
                    <div className="section-title ">
                      <h1
                        className="title"
                        style={{ fontSize: "42px", fontWeight: 450 }}
                      >
                        Akdeniz Konsept
                      </h1>
                      <p className="description">{t("aboutmainpage")}</p>
                      <a
                        className="rn-button-style--2 "
                        href="/Kurumsal"
                        style={{ color: "black", padding: "15px" }}
                      >
                        {t("institutional")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Us Box  */}
        <CustomAccordion />
        <CustomAccordionMobil /> {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--60 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-left service-style--3 mb--30">
                  <h2
                    className="title"
                    style={{
                      fontSize: "45px",
                      fontWeight: 450,
                      textAlign: "center",
                    }}
                  >
                    {t("references")}
                  </h2>
                  <p style={{ textAlign: "center", padding: "0px" }}>
                    {t("referencestextone")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper portfolio-sacousel-inner mb--55">
            <div className="portfolio-slick-activation mt--30 mt_sm--30">
              <Slider {...portfolioSlick2}>
                {PortfolioList2.map((value, index) => (
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        {/* <p>{value.category}</p> */}
                        <h4>
                          <a href={value.link}>{value.title}</a>
                        </h4>
                        <div className="portfolio-button">
                          <Link
                            className="rn-button-style--2"
                            style={{ color: "white", padding: "15px" }}
                            to={value.link}
                          >
                            {t("detail")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* Start Footer Style  */}
        <Footer />
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default withTranslation()(InteriorLanding);
