// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
// Create Import File
import "./index.scss";
import PageScrollTop from "./component/PageScrollTop";
// Home layout
import MainDemo from "./home/MainDemo";
// Dark Home Layout
// Element Layout
import Kurumsal from "./elements/Kurumsal";

import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import Portfolio from "./elements/Portfolio";

// Blocks Layout

import Team from "./blocks/Team";
import VideoPopup from "./blocks/VideoPopup";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// PROJE PAGES
import AlmaResort from "./proje-pages/AlmaResort";
import Regnum from "./proje-pages/Regnum";
import GolfKopru from "./proje-pages/GolfKopru";
import RegnumKuafor from "./proje-pages/RegnumKuafor";
import CaryaGolf from "./proje-pages/CaryaGolf";
import LaraBarut from "./proje-pages/LaraBarut";
import Parke from "./proje-pages/Parke";
import RegnumOda from "./proje-pages/RegnumOda";
import Rixos from "./proje-pages/Rixos";
import SideKarma from "./proje-pages/SideKarma";
import SuSesi from "./proje-pages/SuSesi";
import Şezlong from "./proje-pages/Şezlong";
import Pergole from "./proje-pages/Pergole";
import RegnumCephe from "./proje-pages/RegnumCephe";
import ProjectDetail from "./proje-pages/ProjectDetail";
import LuxuryHouse from "./proje-pages/LuxuryHouse";
import StandarHouse from "./proje-pages/StandartHouse";
//Translate
import { I18nextProvider } from "react-i18next";
import i18n from "./home/i18n";
//Category Pages
import Mobilya from "./category/Mobilya";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <I18nextProvider i18n={i18n}>
            <Switch>
              {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={MainDemo}
              />
              {/* Category Pages START */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/mobilya`}
                component={Mobilya}
              />
              {/* Category Pages END */}

              {/* PROJE PAGES*/}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/rixos`}
                component={Rixos}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/pergole`}
                component={Pergole}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/sezlong`}
                component={Şezlong}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/su-sesi`}
                component={SuSesi}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/regnum-cephe`}
                component={RegnumCephe}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/side-karma`}
                component={SideKarma}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/regnum-oda`}
                component={RegnumOda}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/regnum-kuafor`}
                component={RegnumKuafor}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/alma-resort`}
                component={AlmaResort}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/carya-golf`}
                component={CaryaGolf}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/luxury-ucgen-ev`}
                component={LuxuryHouse}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/standart-ucgen-ev`}
                component={StandarHouse}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/lara-barut`}
                component={LaraBarut}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/parke-zemin`}
                component={Parke}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/regnum`}
                component={Regnum}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/golf-kopru`}
                component={GolfKopru}
              />

              {/* Element Layot */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/kurumsal`}
                component={Kurumsal}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/proje-detay`}
                component={ProjectDetail}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={Contact}
              />

              {/* Blocks Elements  */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/team`}
                component={Team}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/portfolio`}
                component={Portfolio}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/video-popup`}
                component={VideoPopup}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/404`}
                component={error404}
              />
              <Route component={error404} />
            </Switch>
          </I18nextProvider>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
