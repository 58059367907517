import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import "../elements/kurumsal.css";
import { withTranslation } from "react-i18next";

class Service extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="kurumsal" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Kurumsal"} />
        {/* End Breadcrump Area */}
        <div className="about-area pb--10 pt--50 bg_color--5  ">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <h1
                    className="textMobil"
                    style={{
                      textAlign: "center",
                      paddingBottom: "30px",
                      fontWeight: 300,
                      fontSize: "50px",
                    }}
                  >
                    Akdeniz Konsept
                  </h1>
                </div>

                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        - {t("aboutus1")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus2")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <br />
                  <br />
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        - {t("aboutus3")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus4")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <br />
                  <br />
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        - {t("aboutus5")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus6")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Service Area */}
        <div className="about-area ptb--40 bg_color--5 ">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <br />

                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        - {t("aboutus7")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus8")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area ptb--40 bg_color--5 ">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <br />

                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        - {t("aboutus9")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus10")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Service);
