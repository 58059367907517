import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              src="\assets\images\ProjePages\logolar\botam-yapi-grubu.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\DOĞAN HOTEL LOGO.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\Ela-Quality-Resort-Hotel.jpg"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\REGNUM LOGO.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\rixos-hotels-vector-logo.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\susesi.png"
              alt="Logo Images"
            />
          </li>
          {/* <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li> */}
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
