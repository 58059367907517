import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ProductFilter from "../filter/ProductFilter";
import BrandTwo from "./BrandTwo";
import { withTranslation } from "react-i18next";

class Portfolio extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Projelerimiz" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Projelerimiz"} />
        {/* End Breadcrump Area */}
        {/* Start Portfolio Area */}

        {/* End Portfolio Area */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30">
                    <h2 className="title" style={{ fontWeight: 400 }}>
                    {t("aboutus2")}
                    </h2>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-lg-12" style={{ minHeight: "100vh" }}>
                  <ProductFilter
                    styevariation="text-left mt--40"
                    column="col-lg-4 col-md-4 col-sm-6 col-12"
                    item="36"
                  />
                </div>
              </div>
              {/* BRAND START */}
              <div className="rn-brand-area brand-separation bg_color--5 ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <BrandTwo />
                    </div>
                  </div>
                </div>
              </div>
              {/* BRAND END */}
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Portfolio);
