import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Breadcrumb from "../elements/common/Breadcrumb";
import Footer from "../component/footer/Footer";
import img1 from "../../public/assets/images/mobilya-2.jpg";

import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PortfolioList2 = [
  {
    image: "image-2",
    title: " National Golf Club Köprü",
    link: "/golf-kopru",
  },
  {
    image: "image-20",
    title: "Luxury Üçgen Ev",
    link: "/luxury-ucgen-ev",
  },
  {
    image: "image-22",
    title: "Standart Üçgen Ev",
    link: "/standart-ucgen-ev",
  },
  {
    image: "image-1",
    title: "Alma Restaurant Side ",
    link: "/alma-resort",
  },
  {
    image: "image-2",
    title: " National Golf Club Köprü",
    link: "/golf-kopru",
  },

  {
    image: "image-3",
    title: " Regnum Carya VIP  ",
    link: "/regnum",
  },
  {
    image: "image-14",
    title: "Rixos Premium",
    link: "/rixos",
  },
];

const Categories = [
  { title: "Sahil Mobilyaları", href: "/sahil-mobilyalari" },
  { title: "Kent Mobilyaları ", href: "/kent-mobilyalari" },
  { title: "Bahçe Mobilyaları", href: "/sahil-mobilyalari" },
];
class Mobilya extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}
        <div style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="row">
              {/* Title Start */}
              <div className="col-sm-12">
                <h1
                  style={{
                    fontSize: "50px",
                    fontWeight: 500,
                    textAlign: "center",
                    lineHeight: 3,
                  }}
                >
                  {t("furniture")}
                </h1>
              </div>
              {/* Title End */}

              {/* Content Start */}
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-12">
                    <p style={{ fontSize: "17px" }}>
                      Mobilya, evlerimizin vazgeçilmez bir parçasıdır.
                      İşlevselliği ve estetiği bir araya getiren mobilyalar,
                      yaşam alanlarımızı daha kullanışlı ve görsel olarak çekici
                      hale getirirler. Mobilya seçimi, kişisel tarzımızı
                      yansıtmanın yanı sıra, evin iç mekan tasarımını da büyük
                      ölçüde etkiler.
                    </p>
                    <p style={{ fontSize: "17px" }}>
                      Evimizdeki mobilyalar, oturma odasından yatak odasına,
                      mutfaktan banyoya kadar her odada farklı amaçlar için
                      kullanılır. Rahat bir koltuk veya kanepe, misafirleri
                      ağırlamak için idealdir, bir yemek masası ve sandalyeler
                      aile ve arkadaşlarla yemeklerin tadını çıkarmak için
                      gereklidir. Yatak odası mobilyaları, dinlenmek ve
                      rahatlamak için önemlidir.Mobilya seçerken malzeme
                      kalitesi, dayanıklılık ve tasarım gibi faktörleri göz
                      önünde bulundurmalıyız. Ahşap, metal, cam ve plastik gibi
                      farklı malzemeler mobilya üretiminde kullanılır.
                    </p>
                  </div>
                  {/* images start */}
                  <div className="row" style={{ marginBlock: "30px" }}>
                    <br />
                    <br />
                    <div className="col-sm-6">
                      <img
                        src={img1}
                        alt="Mobilya Resmi"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <img
                        src={img1}
                        alt="Mobilya Resmi"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>
                  </div>
                  {/* images end */}
                  {/* images start */}
                  <div className="row" style={{ marginBlock: "30px" }}>
                    <div className="col-sm-6">
                      <img
                        src={img1}
                        alt="Mobilya Resmi"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <img
                        src={img1}
                        alt="Mobilya Resmi"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>
                  </div>
                  {/* images end */}
                  <p style={{ fontSize: "17px" }}>
                    Mobilya, evlerimizin vazgeçilmez bir parçasıdır.
                    İşlevselliği ve estetiği bir araya getiren mobilyalar, yaşam
                    alanlarımızı daha kullanışlı ve görsel olarak çekici hale
                    getirirler. Mobilya seçimi, kişisel tarzımızı yansıtmanın
                    yanı sıra, evin iç mekan tasarımını da büyük ölçüde etkiler.
                  </p>
                  <p style={{ fontSize: "17px" }}>
                    Evimizdeki mobilyalar, oturma odasından yatak odasına,
                    mutfaktan banyoya kadar her odada farklı amaçlar için
                    kullanılır. Rahat bir koltuk veya kanepe, misafirleri
                    ağırlamak için idealdir, bir yemek masası ve sandalyeler
                    aile ve arkadaşlarla yemeklerin tadını çıkarmak için
                    gereklidir. Yatak odası mobilyaları, dinlenmek ve rahatlamak
                    için önemlidir.Mobilya seçerken malzeme kalitesi,
                    dayanıklılık ve tasarım gibi faktörleri göz önünde
                    bulundurmalıyız. Ahşap, metal, cam ve plastik gibi farklı
                    malzemeler mobilya üretiminde kullanılır.
                  </p>
                </div>
              </div>
              {/* Content End */}

              {/* other category start */}
              <div className="col-sm-3">
                {Categories.map((value, index) => (
                  <div key={index}>
                    <p
                      style={{
                        textAlign: "start",
                        fontSize: "19px",
                        marginInlineStart: "40px",
                        color: "darkgrey",
                        lineHeight: "2",
                      }}
                    >
                      <a href={value.href}> {value.title}</a>
                    </p>
                  </div>
                ))}
              </div>
              {/* other category end */}
            </div>
          </div>
        </div>
        {/* Referances Start */}
        <div className="portfolio-area ptb--60 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-left service-style--3 mb--30">
                  <h2
                    className="title"
                    style={{
                      fontSize: "45px",
                      fontWeight: 450,
                      textAlign: "center",
                    }}
                  >
                    {t("references")}
                  </h2>
                  <p style={{ textAlign: "center", padding: "0px" }}>
                    {t("referencestextone")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper portfolio-sacousel-inner mb--55">
            <div className="portfolio-slick-activation mt--30 mt_sm--30">
              <Slider {...portfolioSlick2}>
                {PortfolioList2.map((value, index) => (
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        {/* <p>{value.category}</p> */}
                        <h4>
                          <a href={value.link}>{value.title}</a>
                        </h4>
                        <div className="portfolio-button">
                          <Link
                            className="rn-button-style--2"
                            style={{ color: "white", padding: "15px" }}
                            to={value.link}
                          >
                            {t("detail")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* Referances End */}

        {/* Start Footer Style  */}
        <Footer />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default withTranslation()(Mobilya);
