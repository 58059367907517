import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { withTranslation } from "react-i18next";

const TabFour = [
  {
    image: "/assets/images/ProjePages/CARYA-GOLF-TERAS-DECK-DÖŞEME/4.jpg",
    bigImage: "/assets/images/ProjePages/CARYA-GOLF-TERAS-DECK-DÖŞEME/4.jpg",
    category: "Logo Design",
    title: "Getting tickets to the big show",
  },
];

const Text = [
  {
    title: "Carya Golf Teras",
    endDate: "Nisan 2022",
    alan: "1210 m2",
    yer: "Side / Manavgat / Antalya",
    work: "Proje Geliştirme ve Anahtar Teslim İnşaat",
  },
];
class CaryaGolf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab4: 0,
      isOpen: false,
    };
  }
  render() {
    const { t } = this.props;
    const { column } = this.props;
    const { tab4, isOpen } = this.state;
    return (
      <React.Fragment>
        <PageHelmet pageTitle={Text[0].title} />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={Text[0].title} />
        {/* End Breadcrump Area */}
        {/* Start Portfolio Area */}

        <div className="rn-portfolio-details ptb--60 bg_color--1">
          <div className="row" style={{ paddingInline: "40px" }}>
            <div className="col-lg-9">
              <div className="designer-portfolio-area  bg_color--1">
                <div className="wrapper ">
                  <Tabs column="col-lg-3 col-md-6 col-sm-6 col-12">
                    <TabPanel className="row ">
                      {TabFour.map((value, index) => (
                        <div className={`${column}`} key={index}>
                          {isOpen && (
                            <Lightbox
                              mainSrc={TabFour[tab4].bigImage}
                              nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                              prevSrc={
                                TabFour[
                                  (tab4 + TabFour.length - 1) % TabFour.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen: false })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  tab4:
                                    (tab4 + TabFour.length - 1) %
                                    TabFour.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  tab4: (tab4 + 1) % TabFour.length,
                                })
                              }
                              imageLoadErrorMessage="Yükleniyor ..."
                              enableZoom={true}
                            />
                          )}
                          <div className="item-portfolio-static">
                            <div
                              onClick={() =>
                                this.setState({
                                  isOpen: true,
                                  tab4: index,
                                })
                              }
                            >
                              <div className="portfolio-static ml-1 mr-1 ">
                                <div className="thumbnail-inner">
                                  <div className="thumbnail ">
                                    <a href="#galeri">
                                      <img
                                        height={250}
                                        width={325}
                                        src={value.image}
                                        alt="Proje görseli"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-lg-3 ptb--60">
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: 500,
                  borderBottom: "1px solid #adb5bd",
                  textAlign: "center",
                }}
              >
                {Text[0].title}
              </h1>

              <p style={{ fontSize: "15px", color: "#495057" }}>
                <span style={{ fontWeight: 600 }}>
                  {t("enddate")}&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                </span>
                {Text[0].endDate}
              </p>
              <p style={{ fontSize: "15px", color: "#495057" }}>
                <span style={{ fontWeight: 600 }}>
                  {t("totalarea")} &nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                </span>
                {Text[0].alan}
              </p>
              <p style={{ fontSize: "15px", color: "#495057" }}>
                <span style={{ fontWeight: 600 }}>
                  {t("place")} &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                </span>
                {Text[0].yer}
              </p>
              <p style={{ fontSize: "15px", color: "#495057" }}>
                <span style={{ fontWeight: 600 }}>
                  {t("workdone")} &nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                </span>
                {Text[0].work}
              </p>
            </div>
          </div>
        </div>
        {/* <PortfolioGalleryPopup /> GALERİ POP UP ******************************* */}

        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(CaryaGolf);
