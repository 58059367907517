import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import trFlag from "../../../public/assets/images/icons/tr.webp";
import engFlag from "../../../public/assets/images/icons/eng.png";
import { withTranslation } from "react-i18next";

class Header extends Component {
  changeLanguage = (language) => {
    const { i18n } = this.props;
    i18n.changeLanguage(language);
  };
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    const { t } = this.props;
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    // var elements = document.querySelectorAll(".has-droupdown > a");
    // for (var i in elements) {
    //   if (elements.hasOwnProperty(i)) {
    //     elements[i].onclick = function () {
    //       this.parentElement
    //         .querySelector(".submenu")
    //         .classList.toggle("active");
    //       this.classList.toggle("open");
    //     };
    //   }
    // }

    return (
      <header
        className="header-area formobile-menu header--fixed "
        // style={{
        //   backgroundColor: "rgba(0, 0, 0, 0.1)",
        //   borderRadius: "50px",
        //   marginTop: "10px",
        // }}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left ">
            <div className="logo">
              <a href="/">
                <img
                  className="logo-1"
                  src="/assets/images/logo/akdeniz-white.png"
                  alt="akdeniz konsept"
                  height={"50px"}
                />
                <img
                  className="logo-2"
                  src="/assets/images/logo/akdeniz-white.png"
                  alt="akdeniz konsept"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">
                    <p style={{ fontSize: "18px" }}> {t("home")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/kurumsal">
                    <p style={{ fontSize: "18px" }}> {t("institutional")} </p>
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio">
                    <p style={{ fontSize: "18px" }}> {t("references")}</p>
                  </Link>
                </li>

                <li>
                  <Link to="/contact">
                    <p style={{ fontSize: "18px" }}> {t("contact")} </p>
                  </Link>
                </li>
                <li>
                  <div style={{ paddingTop: "15px" }}>
                    <button
                      onClick={() => this.changeLanguage("tr")}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <img
                        src={trFlag}
                        alt=""
                        style={{ width: "40px", height: "30px" }}
                      />
                    </button>
                  </div>
                </li>
                <li>
                  <div style={{ paddingTop: "15px" }}>
                    <button
                      onClick={() => this.changeLanguage("en")}
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={engFlag}
                        alt=""
                        style={{ width: "40px", height: "30px" }}
                      />
                    </button>
                  </div>
                </li>
              </ul>
            </nav>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
                style={{
                  backgroundColor: "#000000c0",
                  paddingInline: 15,
                  borderRadius: "20px",
                }}
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(Header);
