import React, { Component } from "react";
import data from "./data.json";
import { Link } from "react-router-dom";
import "./ProductFilter.css";
import { withTranslation } from "react-i18next";

class ProductFilter extends Component {
  constructor() {
    super();
    this.state = {
      filteredProducts: [],
      selectedCategoryIds: [],
      selectedBrandId: "",
    };
  }

  handleCategoryChange = (e) => {
    const selectedCategoryId = parseInt(e.target.value);
    let { selectedCategoryIds } = this.state;

    if (selectedCategoryIds.includes(selectedCategoryId)) {
      selectedCategoryIds = selectedCategoryIds.filter(
        (id) => id !== selectedCategoryId
      );
    } else {
      selectedCategoryIds = [selectedCategoryId];
    }

    this.setState({ selectedCategoryIds }, this.filterProducts);
  };

  handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    this.setState({ selectedBrandId }, this.filterProducts);
  };

  filterProducts = () => {
    const { selectedCategoryIds, selectedBrandId } = this.state;

    const filteredProducts = data.filter((product) => {
      const categoryIds = Array.isArray(product.categoryIds)
        ? product.categoryIds
        : [product.categoryIds];
      const categoryMatch =
        selectedCategoryIds.length === 0 ||
        selectedCategoryIds.some((id) => categoryIds.includes(id));
      const brandMatch =
        !selectedBrandId || product.brandId === parseInt(selectedBrandId);
      return categoryMatch && brandMatch;
    });

    this.setState({ filteredProducts });
  };

  componentDidMount() {
    this.filterProducts();
  }

  render() {
    const { t } = this.props;

    const { filteredProducts, selectedCategoryIds, selectedBrandId } =
      this.state;
    const { column, styevariation } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-2">
                <label style={{ fontSize: "17px", fontWeight: 400 }}>
                  {t("category")}:
                </label>
              </div>
              <div className="col-sm-10 select">
                <select
                  value={selectedCategoryIds}
                  onChange={this.handleCategoryChange}
                  style={{ width: "300px" }}
                >
                  <option value="0"> {t("allprojects")}</option>
                  <option value="9">{t("woodenhouse")}</option>
                  <option value="1">{t("woodenconstruction")}</option>
                  <option value="2">{t("pergola")}</option>
                  <option value="3">{t("banister")}</option>
                  <option value="4">{t("flooring")}</option>
                  <option value="5">{t("facadecladding")}</option>
                  <option value="6">{t("beachbuildings")} </option>
                  <option value="7"> {t("bridge")} </option>
                  <option value="8"> {t("furniture")}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-2">
                <label
                  style={{
                    fontSize: "17px",
                    fontWeight: 400,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px",
                  }}
                >
                  {t("company")}:
                </label>
              </div>
              <div className="col-sm-10 select">
                <select
                  value={selectedBrandId}
                  onChange={this.handleBrandChange}
                  style={{ width: "300px" }}
                >
                  <option value="">{t("allprojects")}</option>
                  <option value="11">Selvi Hanım</option>
                  <option value="1">Alma Resort</option>
                  <option value="2">Regnum Carya</option>
                  <option value="3">Lara Barut</option>
                  <option value="5">Zeynep Hotel</option>
                  <option value="6">Rixos Premium</option>
                  <option value="7">Susesi Luxury Resort</option>
                  <option value="8">National Golf Club</option>
                  <option value="9">Karma Restaurant</option>
                  <option value="10">{t("othercompany")}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            padding: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="row" style={{ padding: "0px" }}>
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className={`col-sm-4`}
                style={{ height: "440px", padding: "0px" }}
              >
                <div className={`portfolio ${styevariation}`}>
                  <div
                    className="thumbnail-inner mobilimg pcimg"
                    style={{ height: "400px" }}
                  >
                    <div className={`thumbnail ${product.image}`}></div>
                    <div className={`bg-blr-image ${product.image}`}></div>
                  </div>
                  <div className="content mobilimgtext pcimgtext">
                    <div className="inner">
                      <h4 className="shorttext shorttextmobil">
                        <a href={product.link} className="shorttext">
                          {product.title}
                        </a>
                      </h4>
                      <div className="portfolio-button">
                        <a
                          className="rn-btn"
                          href={product.link}
                          style={{
                            backgroundColor: "#b1b4c166",
                            borderColor: "#b1b4c166",
                          }}
                        >
                          {t("details")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <Link className="link-overlay" to={product.link}></Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProductFilter);
